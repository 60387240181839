// src/components/EasterEgg.js

import React from 'react';
import styles from './EasterEgg.module.css';

const EasterEgg = ({ onClose }) => {
  return (
    <div className={styles.easterEgg} onClick={onClose}>
      <img 
        src="https://pbs.twimg.com/media/GTqoW8eX0AED4z_?format=jpg&name=small" 
        alt="Easter Egg"
        className={styles.easterEggImage}
      />
    </div>
  );
};

export default EasterEgg;