import React, { useState, useEffect } from "react";
import { Contract, RpcProvider } from "starknet";
import { connect } from "get-starknet";
import styles from "./SlinkClaim.module.css";
import { contractABI } from "./contractABI";
import slinkImage from './slink-image.png';

const DISTRIBUTOR_ADDRESS = "0x0107be20a14db436968cbb9f22aa2e72357977c57b00320da79577c5627b3d8c";

const SlinkDistributor = () => {
  const [account, setAccount] = useState(null);
  const [remainingAlloc, setRemainingAlloc] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [powerOn, setPowerOn] = useState(false);

  const provider = new RpcProvider({
    nodeUrl: 'https://starknet-mainnet.public.blastapi.io/rpc/v0_7',
  });

  useEffect(() => {
    const timer = setTimeout(() => setPowerOn(true), 500);
    return () => clearTimeout(timer);
  }, []);

  const handleConnect = async () => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const starknet = await connect();
      if (!starknet) throw new Error("Failed to connect to wallet");
      await starknet.enable();
      if (starknet.isConnected && starknet.provider && starknet.account.address) {
        setAccount(starknet.account);
        await fetchRemainingAlloc(starknet.account.address);
      }
    } catch (error) {
      console.error("Error connecting wallet:", error);
      setErrorMessage("Failed to connect wallet. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRemainingAlloc = async (address) => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const contractInstance = new Contract(contractABI, DISTRIBUTOR_ADDRESS, provider);
      const alloc = await contractInstance.getUserRemainingAlloc(address);
      setRemainingAlloc(alloc.toString());
    } catch (error) {
      console.error("Error fetching allocation:", error);
      setErrorMessage("Failed to fetch allocation. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleClaim = async () => {
    if (!account) return;
    if (remainingAlloc === "0") {
      setErrorMessage("No tokens available to claim.");
      return;
    }
    setIsLoading(true);
    setErrorMessage("");
    try {
      const contractInstance = new Contract(contractABI, DISTRIBUTOR_ADDRESS, provider);
      contractInstance.connect(account);
      const result = await contractInstance.claim();
      await provider.waitForTransaction(result.transaction_hash);
      await fetchRemainingAlloc(account.address);
    } catch (error) {
      console.error("Error claiming tokens:", error);
      setErrorMessage("Failed to claim tokens. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleTwitterShare = () => {
    const allocationAmount = remainingAlloc 
      ? (parseInt(remainingAlloc) / Math.pow(10, 18)).toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 0
        })
      : "???";
    const tweetText = encodeURIComponent(`🚀 Just claimed ${allocationAmount} $SLINK with my @StarkGuardians!\nJust $SLINK it 👀`);
    const twitterUrl = `https://twitter.com/intent/tweet?text=${tweetText}`;
    window.open(twitterUrl, '_blank');
  };

  const canClaim = remainingAlloc !== null && remainingAlloc !== "0";

  return (
    <div className={`${styles.console} ${powerOn ? styles.powerOn : ""}`}>
      <div className={styles.screen}>
        <div className={styles.scanlines}></div>
        <div className={styles.content}>
          <img src={slinkImage} alt="Slink" className={styles.slinkImage} />
          <h1 className={styles.title}>SLINK DISTRIBUTOR</h1>
          <div className={styles.textContent}>
            {!account ? (
              <button
                className={styles.connectButton}
                onClick={handleConnect}
                disabled={isLoading}
              >
                {isLoading ? "CONNECTING..." : "CONNECT WALLET"}
              </button>
            ) : (
              <>
                <div className={styles.stats}>
                  <span>ADDRESS: {account.address.slice(0, 6)}...{account.address.slice(-4)}</span>
                  <span>REMAINING ALLOC: {
                    remainingAlloc 
                      ? (parseInt(remainingAlloc) / Math.pow(10, 18)).toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 0
                        })
                      : "???"
                  }</span>
                </div>
                <button
                  className={`${styles.actionButton} ${styles.claimButton}`}
                  onClick={handleClaim}
                  disabled={isLoading || !canClaim}
                >
                  {isLoading ? "CLAIMING..." : canClaim ? "CLAIM SLINK" : "NO TOKENS TO CLAIM"}
                </button>
                <button
                  className={`${styles.actionButton} ${styles.twitterButton}`}
                  onClick={handleTwitterShare}
                >
                  SHARE ON TWITTER
                </button>
              </>
            )}
            {errorMessage && <div className={styles.error}>{errorMessage}</div>}
          </div>
        </div>
      </div>
      <div className={styles.insertCoin}>INSERT COIN TO CLAIM</div>
    </div>
  );
};

export default SlinkDistributor;