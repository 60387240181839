import React, { useEffect, useState } from 'react';
import Grid from './components/Grid';
import EasterEgg from './components/EasterEgg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SlinkDistributor from './components/SlinkClaim';

const websites = [
  { url: 'https://app.avnu.fi/en?amount=10&tokenFrom=0x53c91253bc9682c04929ca02ed00b3e423f6710d2ee7e0d5ebb06f3ecf368a8&tokenTo=0x013ff4e86fa3e7286cc5c64b62f4099cf41e7918d727d22a5109ecfd00274d19', title: 'Avnu', thumbnail: 'https://doc.avnu.fi/~gitbook/image?url=https%3A%2F%2F1433483397-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FBEENXwkITptyXUHEEOyz%252Fuploads%252F7NueSM5JFo6lyHh9GgRq%252FAVNU_logo_white.png%3Falt%3Dmedia%26token%3D2978a639-0e81-4f10-9115-caa6de4d6616&width=768&dpr=2&quality=100&sign=518fb438&sv=1' },
  { url: 'https://x.com/slinkystark', title: 'X', thumbnail: 'https://upload.wikimedia.org/wikipedia/commons/5/57/X_logo_2023_%28white%29.png' },
  { url: 'https://t.me/slinkystarknet', title: 'Telegram', thumbnail: 'https://upload.wikimedia.org/wikipedia/commons/4/40/Deus_Telegram.png' },
  { url: 'https://dexscreener.com/starknet/0x013ff4e86fa3e7286cc5c64b62f4099cf41e7918d727d22a5109ecfd00274d19-0x049d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7-1020847100762815390390123822295304634-5982-0x0', title: 'DexScreener', thumbnail: 'https://dexscreener.com/favicon.png' },
];
function App() {
  const [showEasterEgg, setShowEasterEgg] = useState(false);

  useEffect(() => {
    let inactivityTimer;

    const resetTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => {
        setShowEasterEgg(true);
      }, 30000); // 0.5 minutes (30,000 milliseconds)
    };

    // Reset the timer on user activity
    const handleActivity = () => {
      resetTimer();
    };

    // Add event listeners for user activity
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);
    window.addEventListener('click', handleActivity);
    window.addEventListener('scroll', handleActivity);

    // Initial timer setup
    resetTimer();

    // Cleanup
    return () => {
      clearTimeout(inactivityTimer);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
      window.removeEventListener('click', handleActivity);
      window.removeEventListener('scroll', handleActivity);
    };
  }, []);

  const closeEasterEgg = () => {
    setShowEasterEgg(false);
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={
            <>
              <Grid websites={websites} />
              {showEasterEgg && <EasterEgg onClose={closeEasterEgg} />}
            </>
          } />
          <Route path="/claim" element={<SlinkDistributor />} />
        </Routes>
      </div>
    </Router>
  );
}
export default App;