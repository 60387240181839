export const contractABI = [
  {
    type: "impl",
    name: "OwnableImpl",
    interface_name: "openzeppelin::access::ownable::interface::IOwnable",
  },
  {
    type: "interface",
    name: "openzeppelin::access::ownable::interface::IOwnable",
    items: [
      {
        type: "function",
        name: "owner",
        inputs: [],
        outputs: [
          { type: "core::starknet::contract_address::ContractAddress" },
        ],
        state_mutability: "view",
      },
      {
        type: "function",
        name: "transfer_ownership",
        inputs: [
          {
            name: "new_owner",
            type: "core::starknet::contract_address::ContractAddress",
          },
        ],
        outputs: [],
        state_mutability: "external",
      },
      {
        type: "function",
        name: "renounce_ownership",
        inputs: [],
        outputs: [],
        state_mutability: "external",
      },
    ],
  },
  {
    type: "impl",
    name: "LpFactory",
    interface_name: "pyramidlp::launchpad::distributor::ISGNDistributor",
  },
  {
    type: "struct",
    name: "core::integer::u256",
    members: [
      { name: "low", type: "core::integer::u128" },
      { name: "high", type: "core::integer::u128" },
    ],
  },
  {
    type: "interface",
    name: "pyramidlp::launchpad::distributor::ISGNDistributor",
    items: [
      {
        type: "function",
        name: "getTotalDistributed",
        inputs: [],
        outputs: [{ type: "core::integer::u256" }],
        state_mutability: "view",
      },
      {
        type: "function",
        name: "getUserRemainingAlloc",
        inputs: [
          {
            name: "_addr",
            type: "core::starknet::contract_address::ContractAddress",
          },
        ],
        outputs: [{ type: "core::integer::u256" }],
        state_mutability: "view",
      },
      {
        type: "function",
        name: "claim",
        inputs: [],
        outputs: [],
        state_mutability: "external",
      },
      {
        type: "function",
        name: "setUsersAlloc",
        inputs: [
          {
            name: "recipients",
            type: "core::array::Array::<core::starknet::contract_address::ContractAddress>",
          },
          {
            name: "amounts",
            type: "core::array::Array::<core::integer::u256>",
          },
        ],
        outputs: [],
        state_mutability: "external",
      },
      {
        type: "function",
        name: "setFundStorageAddress",
        inputs: [
          {
            name: "address",
            type: "core::starknet::contract_address::ContractAddress",
          },
        ],
        outputs: [],
        state_mutability: "external",
      },
    ],
  },
  {
    type: "constructor",
    name: "constructor",
    inputs: [
      {
        name: "owner",
        type: "core::starknet::contract_address::ContractAddress",
      },
      {
        name: "fundingAddr",
        type: "core::starknet::contract_address::ContractAddress",
      },
    ],
  },
  {
    type: "event",
    name: "pyramidlp::launchpad::distributor::SGNDistributor::OwnershipTransferred",
    kind: "struct",
    members: [
      {
        name: "previous_owner",
        type: "core::starknet::contract_address::ContractAddress",
        kind: "data",
      },
      {
        name: "new_owner",
        type: "core::starknet::contract_address::ContractAddress",
        kind: "data",
      },
    ],
  },
  {
    type: "event",
    name: "pyramidlp::launchpad::distributor::SGNDistributor::claimed",
    kind: "struct",
    members: [
      { name: "time", type: "core::integer::u64", kind: "data" },
      {
        name: "address",
        type: "core::starknet::contract_address::ContractAddress",
        kind: "data",
      },
      { name: "amount", type: "core::integer::u256", kind: "data" },
    ],
  },
  {
    type: "event",
    name: "pyramidlp::launchpad::distributor::SGNDistributor::Event",
    kind: "enum",
    variants: [
      {
        name: "OwnershipTransferred",
        type: "pyramidlp::launchpad::distributor::SGNDistributor::OwnershipTransferred",
        kind: "nested",
      },
      {
        name: "Claimed",
        type: "pyramidlp::launchpad::distributor::SGNDistributor::claimed",
        kind: "nested",
      },
    ],
  },
];
