import React, { useState, useEffect } from 'react';
import { Copy } from 'lucide-react';
import styles from './Console.module.css';

const Grid = ({ websites }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [glitchEffect, setGlitchEffect] = useState(false);
  const [activeIframe, setActiveIframe] = useState(null);
  const [showCopied, setShowCopied] = useState(false);

  const CA = "0x013ff4e86fa3e7286cc5c64b62f4099cf41e7918d727d22a5109ecfd00274d19";
  // Split CA into two parts for mobile display
  const CA_PART1 = CA.slice(0, 34);
  const CA_PART2 = CA.slice(34);

  useEffect(() => {
    const glitchInterval = setInterval(() => {
      setGlitchEffect(true);
      setTimeout(() => setGlitchEffect(false), 100);
    }, 5000);

    return () => clearInterval(glitchInterval);
  }, []);

  const handleWebsiteClick = (website, index) => {
    setSelectedIndex(index);
    if (website.title.toLowerCase() === 'avnu' ) {
      setActiveIframe(website.url);
    } else {
      window.open(website.url, '_blank');
    }
  };

  const copyCA = async () => {
    try {
      await navigator.clipboard.writeText(CA);
      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy CA:', err);
    }
  };

  return (
    <div className={`${styles.console} ${glitchEffect ? styles.glitch : ''} ${!activeIframe ? styles.consoleActive : ''}`}>
      <div className={styles.contentWrapper}>
        {activeIframe ? (
          <div className={styles.iframeContainer}>
            <iframe className={styles.iframe} src={activeIframe} title="Active Website" />
            <button className={styles.closeButton} onClick={() => setActiveIframe(null)}>
              X
            </button>
          </div>
        ) : (
          <>
            <div className={styles.header}>
              <div className={styles.credit}>CREDIT 9999</div>
              <div className={styles.highScore}>HI-SCORE 999999</div>
            </div>
            <h1 className={styles.title}>APE IN MFER</h1>
            <div className={styles.websiteGrid}>
              {websites.map((website, index) => (
                <div
                  key={index}
                  className={`
                    ${styles.websiteItem} 
                    ${index === selectedIndex ? styles.selected : ''} 
                    ${website.title.toLowerCase() === 'avnu' ? styles.avnuItem : ''}
                  `}
                  onClick={() => handleWebsiteClick(website, index)}
                >
                  <img
                    className={styles.websiteImage}
                    src={website.thumbnail}
                    alt={website.title}
                  />
                  <div className={styles.websiteTitle}>{website.title}</div>
                </div>
              ))}
            </div>
            <div className={styles.footerContainer}>
              <div 
                className={styles.caText}
                onClick={copyCA}
              >
                <div className={styles.caContent}>
                  <span className={styles.caDesktop}>{CA}</span>
                  <div className={styles.caMobile}>
                    <span>{CA_PART1}</span>
                    <span>{CA_PART2}</span>
                  </div>
                  <Copy className={styles.copyIcon} size={16} />
                  {showCopied && <span className={styles.copiedMessage}>Copied!</span>}
                </div>
              </div>
              <div className={styles.insertCoin}>ITS SLINK SEASON</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Grid;